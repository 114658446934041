<template>
  <div class="v-line"></div>
  <el-menu
    class="border-0"
    :mode="settingsStore.mode === 'pc' ? 'horizontal' : 'vertical'"
    :collapse="settingsStore.isCollapse"
    :router="true"
    :close-on-click-outside="true"
    :unique-opened="true"
    menu-trigger="click"
  >
    <template v-for="(item, index) in menuStore.menus" :key="index">
      <el-sub-menu v-if="item.isSlot" :teleported="true" :index="index + ''">
        <template #title>
          <span
            :class="menuStore.actived == item.meta.fullPath ? 'is-active' : ''"
          >
            {{ item.meta.title }}
          </span>
        </template>
        <div class="nav2Main">
          <div class="navMainLeft">
            <div class="navService">
              <b>服务试用</b>
              <div class="navServiceList">
                <el-menu-item @click="changeActive(item)">
                  <!-- <nuxt-link to="/device/newg2pro"> -->
                  <a
                    class="item-link flex align-center"
                    href="https://remote.daystar.lenovo.com"
                    target="_blank"
                  >
                    联想晨星远程协作系统
                    <img :src="share" />
                  </a>
                  <!-- </nuxt-link> -->
                </el-menu-item>

                <el-menu-item @click="changeActive(item)">
                  <a
                    class="item-link flex align-center"
                    href="https://exhibition.lenovo-mr.com/#/ue4list/index"
                    target="_blank"
                  >
                    元宇宙展厅
                    <img :src="share" />
                  </a>
                </el-menu-item>
              </div>
            </div>
            <div class="navService">
              <b>应用&SDK下载</b>
              <div class="navServiceList">
                <el-menu-item @click="changeActive(item)">
                  <a href="/toolDownload" target="_blank">AR工具&应用</a>
                </el-menu-item>
                <el-menu-item @click="changeActive(item)">
                  <a href="/toolDownload" target="_blank">机器人SDK下载</a>
                </el-menu-item>
                <el-menu-item @click="changeActive(item)">
                  <a
                    class="item-link"
                    href="https://apps.apple.com/cn/app/%E6%99%A8%E6%98%9F%E6%89%AB%E6%8F%8F%E4%BB%AA/id1538419841"
                    target="_blank"
                  >
                    联想晨星3D扫描：stARscan
                  </a>
                </el-menu-item>
                <el-menu-item @click="changeActive(item)">
                  <a href="/sdkDownload" target="_blank">
                    联想晨星SDK：stARkit
                  </a>
                </el-menu-item>
                <el-menu-item @click="changeActive(item)">
                  <a href="https://daystar.lenovo.com/api/v3/storage/fs/public/AISearch/AISearchDeploy.zip" download>
                    晨星AI图像检索产品                  </a>
                </el-menu-item>
              </div>
            </div>
          </div>
          <div class="navMainRight">
            <div class="navService">
              <b>售前咨询</b>
              <div class="navServiceList navCode">
                <div>
                  <p>daystar@lenovo.com</p>
                  <p>电话：181 1611 8490</p>
                </div>
                <!-- <iframe :src="presale" width="300" height="100"></iframe> -->
                <img :src="presale" alt="SVG Image" />

                <!-- <img
                  :src="
                    require('@/assets/image/layout/pre_sale_consultation.jpg')
                  "
                  alt=""
                /> -->
              </div>
            </div>
            <div class="navService adjust-distance">
              <b>售后服务</b>
              <div class="navServiceList navCode">
                <div>
                  <p>Csm_Support@lenovo.com</p>
                  <p>电话：131 2074 5107</p>
                  <!-- <el-menu-item @click="changeActive(item)">
                    <a
                      class="item-link align-center flex"
                      href="https://daystar.lenovo.com/redmine/"
                      target="_blank"
                    >
                      客户支持系统
                      <img :src="share" class="mgl-5" />
                    </a>
                  </el-menu-item> -->
                </div>
                <img :src="aftersale" alt="" />
              </div>
            </div>
          </div>
        </div>
      </el-sub-menu>
      <!-- 二级菜单 -->
      <el-sub-menu
        v-else-if="item.children && item.children.length > 0"
        :teleported="true"
        :index="item.name.toString()"
        :class="menuStore.actived == item.meta.fullPath ? 'is-active' : ''"
      >
        <template #title>
          <span>{{ item.meta.title }}</span>
        </template>
        <!-- 子菜单 -->
        <el-menu-item
          v-for="(item2, index2) in item.children"
          :key="index2"
          :index="item2.meta.fullPath"
          @click="() => handleSelect(item2)"
        >
          <span>{{ item2.meta.title }}</span>
        </el-menu-item>
      </el-sub-menu>
      <!-- 一级菜单 -->
      <el-menu-item
        v-else
        :index="item.meta.fullPath"
        :class="menuStore.actived == item.meta.fullPath ? 'is-active' : ''"
        @click="() => handleSelect(item)"
      >
        <template #title>
          <span>{{ item.meta.title }}</span>
        </template>
        <!-- <span>{{ item.meta.title }}</span> -->
      </el-menu-item>
    </template>
  </el-menu>
</template>
<script setup>
import useMenuStore from '@/store/modules/menu'
import useSettingsStore from '@/store/modules/settings'
import share from '@/assets/images/layout/share.png'
import presale from '@/assets/images/layout/presale.svg'
import aftersale from '@/assets/images/layout/aftersale.svg'
const menuStore = useMenuStore()
const settingsStore = useSettingsStore()
// const activeIndex = ref(menuStore.actived || '/home/index')
const router = useRouter()
const handleSelect = (item) => {
  menuStore.actived = item.meta.fullPath
  if (item.outLink) {
    window.open(item.path, '_blank')
  } else if (item.innerAnchor) {
    router.push(item.path)
    menuStore.isScrolling = true
  } else {
    router.push(item.meta.fullPath)
    document.body.scrollIntoView({
      block: 'start', //容器上下的中间
      inline: 'start', //容器左右的左边
    })
  }
}

const changeActive = (item, url) => {
  menuStore.actived = item.meta.fullPath
  if (url) {
    window.location.href = url
  }
}
</script>
<style lang="scss" scoped>
li,
li span {
  // border:1px solid transparent;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
.v-line {
  background-color: #000;
  height: 25px;
  margin: 0 30px 0 50px;
  width: 2px;
}
.mgl-5 {
  margin-left: 5px;
}
.el-menu {
  border-bottom: 0 none;
  height: 58px;
}

.el-menu--vertical:not(.el-menu--collapse):not(.el-menu--popup-container)
  .el-menu-item,
.el-menu--vertical:not(.el-menu--collapse):not(.el-menu--popup-container)
  .el-menu-item-group__title,
.el-menu--vertical:not(.el-menu--collapse):not(.el-menu--popup-container)
  .el-sub-menu__title {
  background-color: #fff !important;
}

.el-menu--horizontal {
  flex: 1;
  * {
    box-sizing: content-box;
  }
  .el-menu-item {
    border-bottom: 0 none;
    span {
      border-bottom: 2px solid transparent;
      transition: all 0.2s;
    }
    &:hover {
      color: #000;
      background: transparent;

      span {
        border-bottom: 2px solid #000 !important;
      }
    }
    &.is-active {
      border-bottom: 0 none;
    }
    &.is-active span {
      color: #000 !important;
      border-bottom: 2px solid #000;
    }
  }
  .el-sub-menu {
    &:hover :deep(.el-sub-menu__title) {
      color: #000;
      border-bottom: 2px solid#000 !important;
    }
    &.is-active :deep(.el-sub-menu__title) {
      color: #000 !important;
      border-bottom: 2px solid #000;
    }
  }
}
.el-popper {
  .el-menu-item {
    height: 38px;
    span {
      line-height: 36px;
      transition: all 0.2s;
      border-bottom: 2px solid transparent;
    }
  }
  li a {
    line-height: 36px;
    border-bottom: 2px solid transparent;
    transition: all 0.2s;

    &:hover {
      border-bottom: 2px solid #000;
    }
  }
}
.nav2Main {
  width: 570px;
  padding: 10px 14px;
  display: flex;
  flex: none !important;
  .navMainLeft {
    width: 30%;
    flex: 1;
    .navService {
      &:first-child {
        margin-top: 0;
      }
      margin-top: 30px;
      b {
        font-size: 16px;
      }
      .navServiceList {
        display: flex;
        flex-direction: column;
        // font-size: 14px;
        .el-menu-item {
          padding: 0;
          img {
            margin-left: 5px;
          }
          .item-link img {
            width: 13px;
            height: 13px;
            margin-left: 5px;
          }
        }
        a {
          color: #000;
          img {
            margin-left: 5px;
          }
        }
      }
    }
  }
  .navMainRight {
    width: 60%;
    flex: 1;
    display: flex;
    flex-direction: column;
    img {
      margin-left: 5px;
    }
    .navService {
      b {
        font-size: 16px;
      }
      .navServiceList {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        .el-menu-item {
          padding: 0;
          img {
            margin-left: 5px;
          }
        }
        p,
        a {
          line-height: 36px;
        }
        a {
          color: #000;
        }

        img {
          width: 80px;
          margin-left: 20px;
        }
        .item-link img {
          width: 13px;
        }
      }
      .navCode {
        flex-direction: row;
      }
    }
  }
}

:deep(:focus-visible) {
  outline: none !important;
}
@media screen and (max-width: 859px) {
  .v-line {
    background-color: #000;
    height: 25px;
    margin: 0;
    width: 2px;
  }
  .nav2Main {
    width: 570px;
    padding: 10px 14px;
    display: flex;
    flex-direction: column;
    flex: none !important;
    .navMainLeft {
    }
    .navMainRight {
    }
  }
}
</style>
<style>
.el-menu:not(.el-menu--collapse) .el-sub-menu__title {
  background-color: #fff;
}
.el-sub-menu .el-sub-menu__icon-arrow {
  margin-right: 9px;
}
</style>
